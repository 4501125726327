import React, { useEffect, useState } from 'react';
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import './index.css'; 
import { MdDeleteForever } from "react-icons/md";

const FormDataComponent = () => {
  const [enquiries, setEnquiries] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [enquiryToDelete, setEnquiryToDelete] = useState(null);

  useEffect(() => {
    const fetchEnquiries = async () => {
      const enquiriesSnapshot = await firebase.firestore()
      .collection('EnquireFormTU')
      .orderBy('timestamp', 'desc')  
      .get();
      const enquiriesData = enquiriesSnapshot.docs.map(doc => {
        const data = doc.data();
        const updates = {};
        if (data.pending === undefined) {
          updates.pending = true;
        }
        if (data.completed === undefined) {
          updates.completed = false;
        }
        if (data.callbackRequested === undefined) {
          updates.callbackRequested = false;
        }
        if (data.notInterested === undefined) {
          updates.notInterested = false;
        }
        if (Object.keys(updates).length > 0) {
          firebase.firestore().collection('EnquireFormTU').doc(doc.id).update(updates);
          Object.assign(data, updates);
        }
        return { id: doc.id, ...data };
      });
      setEnquiries(enquiriesData);
    };

    fetchEnquiries();
  }, []);

  const handleStatusChange = (id, statusField) => {
    setEnquiries((prevEnquiries) =>
      prevEnquiries.map((enquiry) => {
        if (enquiry.id === id) {
          const updatedStatus = !enquiry[statusField];
          firebase.firestore().collection('EnquireFormTU').doc(id).update({ [statusField]: updatedStatus });
          return { ...enquiry, [statusField]: updatedStatus };
        }
        return enquiry;
      })
    );
  };

  const openDeleteModal = (id) => {
    setEnquiryToDelete(id);
    setIsModalOpen(true);
  };

  const closeDeleteModal = () => {
    setEnquiryToDelete(null);
    setIsModalOpen(false);
  };

  const confirmDelete = async () => {
    if (enquiryToDelete) {
      try {
        await firebase.firestore().collection('EnquireFormTU').doc(enquiryToDelete).delete();
        setEnquiries((prevEnquiries) => prevEnquiries.filter(enquiry => enquiry.id !== enquiryToDelete));
        closeDeleteModal();
      } catch (error) {
        console.error("Error deleting document: ", error);
      }
    }
  };

  return (
    <div className="bookings-container">
      {enquiries.map((enquiry) => (
        <div className="booking-card" key={enquiry.id}>
          <div className="booking-card-header">
            <h3 className="booking-title">Enquiry Details</h3>
            <button className="delete-button" onClick={() => openDeleteModal(enquiry.id)}>
              <MdDeleteForever size={20} />
            </button>
          </div>

          <div className="booking-details">
            <p><strong>Name:</strong> {enquiry.name}</p>
            <p><strong>Email:</strong> {enquiry.email}</p>
            <p><strong>Phone:</strong> {enquiry.phone}</p>
            <p><strong>Holiday Type:</strong> {enquiry.holidayType}</p>
            <p><strong>Destination:</strong> {enquiry.destination}</p>
            <p><strong>Arrival Date:</strong> {enquiry.arrivalDate}</p>
            <p><strong>No.of People:</strong> {enquiry.noOfPeople}</p>
            <p><strong>No.of Kids:</strong> {enquiry.kids}</p>
            <p><strong>Stay Preference:</strong> {enquiry.type}</p>
            <p><strong>Stay Duration:</strong> {enquiry.duration}</p>
          </div>

          <div className="booking-status">
            <label className={`checkbox-container ${enquiry.pending ? 'red' : 'gray'}`}>
              <input
                type="checkbox"
                checked={enquiry.pending}
                onChange={() => handleStatusChange(enquiry.id, 'pending')}
              />
              <span className="custom-checkbox">Pending</span>
            </label>
            <label className={`checkbox-container ${enquiry.callbackRequested ? 'blue' : 'gray'}`}>
              <input
                type="checkbox"
                checked={enquiry.callbackRequested}
                onChange={() => handleStatusChange(enquiry.id, 'callbackRequested')}
              />
              <span className="custom-checkbox">Callback</span>
            </label>
            <label className={`checkbox-container ${enquiry.completed ? 'green' : 'gray'}`}>
              <input
                type="checkbox"
                checked={enquiry.completed}
                onChange={() => handleStatusChange(enquiry.id, 'completed')}
              />
              <span className="custom-checkbox">Completed</span>
            </label>
            <label className={`checkbox-container ${enquiry.notInterested ? 'black' : 'gray'}`}>
              <input
                type="checkbox"
                checked={enquiry.notInterested}
                onChange={() => handleStatusChange(enquiry.id, 'notInterested')}
              />
              <span className="custom-checkbox">Not Interested</span>
            </label>
          </div>
        </div>
      ))}

      {isModalOpen && (
        <div className="modal-overlay">
          <div className="modal-content">
            <p>Are you sure you want to delete this document?</p>
            <div className="modal-buttons">
              <button className="modal-button confirm" onClick={confirmDelete}>Yes</button>
              <button className="modal-button cancel" onClick={closeDeleteModal}>No</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default FormDataComponent;
