import React, { useEffect, useState } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import { FaTimes, FaPlus, FaEdit, FaTrash, FaSave, FaBan } from "react-icons/fa";

const firebaseConfig = {
  apiKey: "AIzaSyB4BPMHKw0OXM2PhQX9NcT25lDBWpRpIPQ",
  authDomain: "phone-auth-d0a8d.firebaseapp.com",
  projectId: "phone-auth-d0a8d",
  storageBucket: "phone-auth-d0a8d.appspot.com",
  messagingSenderId: "309252587015",
  appId: "1:309252587015:web:3f9dc2505965e66269b4a2",
};

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

const TripTable = () => {
  const [trips, setTrips] = useState([]);
  const [searchTripId, setSearchTripId] = useState("");
  const [searchContact, setSearchContact] = useState("");
  const [searchDate, setSearchDate] = useState("");
  const [formVisible, setFormVisible] = useState(false);
  const [formData, setFormData] = useState({
    tripId: "",
    name: "",
    phone: "",
    email: "",
    admin: "",
    dates: [{ date: "", location: "", resort:"", vendor:"", sinclusions:"" }],
  });

  const [editingTripId, setEditingTripId] = useState(null);
  const [editData, setEditData] = useState({});
  const [deleteConfirmTripId, setDeleteConfirmTripId] = useState(null);

 useEffect(() => {
    const fetchData = async () => {
      const querySnapshot = await firebase
        .firestore()
        .collection("customerData")
        .orderBy("dates")
        .get();

      const tripData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      setTrips(tripData); 
    };

    fetchData();
  }, []);


  const addDatePair = () => {
    setFormData({
      ...formData,
      dates: [...formData.dates, { date: "", location: "", resort:"", vendor: "", sinclusions:"" }],
    });
  };

  const handleInputChange = (e, index = null, dataSetter, data) => {
    if (index !== null) {
      const updatedDates = [...data.dates];
      updatedDates[index][e.target.name] = e.target.value;
      dataSetter({ ...data, dates: updatedDates });
    } else {
      dataSetter({ ...data, [e.target.name]: e.target.value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const dataWithTimestamp = { ...formData };
  
    const docRef = await firebase.firestore().collection("customerData").add(dataWithTimestamp);
    const newTripWithId = { id: docRef.id, ...dataWithTimestamp };
  
    setTrips((prevTrips) => {
      return [...prevTrips.filter(trip => trip.id !== newTripWithId.id), newTripWithId].sort((a, b) => {
        return new Date(a.dates[0].date) - new Date(b.dates[0].date);
      });
    });
  
    setFormVisible(false);
    setFormData({
      tripId: "",
      name: "",
      phone: "",
      email: "",
      admin: "",
      dates: [{ date: "", location: "", resort: "", vendor: "", sinclusions:"" }],
    });
  };
  
const handleEditSave = async (trip) => {
  try {
    const docRef = firebase.firestore().collection("customerData").doc(trip.id);

    await docRef.set(editData); 

    const updatedTrips = trips.map((t) =>
      t.id === trip.id ? { ...editData, id: trip.id } : t
    );
    setTrips(updatedTrips);
    setEditingTripId(null);
    setEditData({});
  } catch (error) {
    console.error("Error updating document:", error);
  }
};


  const handleDeleteConfirm = async (trip) => {
    await firebase.firestore().collection("customerData").doc(trip.id).delete();
    setTrips(trips.filter((t) => t.id !== trip.id));
    setDeleteConfirmTripId(null);
  };

  const filteredTrips = trips.filter((trip) => {
    const matchesTripId = searchTripId
      ? trip.tripId.toLowerCase().includes(searchTripId.toLowerCase())
      : true;
    const matchesContact = searchContact
      ? trip.phone.toLowerCase().includes(searchContact.toLowerCase())
      : true;
    const matchesDate =
  searchDate && trip.dates
    ? trip.dates.some((dateObj) => {
        console.log("Raw Date Object:", dateObj);
        if (!dateObj || !dateObj.date) return false;
        const tripDate = dateObj.date; 
        return tripDate === searchDate;
      })
    : true;
    return matchesTripId && matchesContact && matchesDate;
  });

  return (
    <div style={{ maxWidth: "100%", margin: "auto", paddingTop: "20px", fontFamily: "Roboto", color:'black' }}>
      <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "10px", flexWrap: "wrap", gap: "10px" }}>
        <div style={{ display: "flex", gap: "20px", flexWrap: "wrap" }}>
          {["Trip ID", "Contact",].map((label, idx) => {
            const stateMap = {
              "Trip ID": { value: searchTripId, setter: setSearchTripId },
              Contact: { value: searchContact, setter: setSearchContact },
            };
            const { value, setter } = stateMap[label];
            return (
              <div key={label} style={{ position: "relative", width: "200px" }}>
                <input
                  type="text"
                  placeholder={`Search by ${label}`}
                  value={value}
                  onChange={(e) => setter(e.target.value)}
                  style={{
                    padding: "8px",
                    borderRadius: "5px",
                    border: "1px solid #ccc",
                  }}
                />
                {value && (
                  <FaTimes
                    style={{
                      position: "absolute",
                      right: "10px",
                      top: "50%",
                      transform: "translateY(-50%)",
                      cursor: "pointer",
                      color: "#999",
                    }}
                    onClick={() => setter("")}
                  />
                )}
              </div>
            );
          })}
          <div style={{ position: "relative", width: "200px", cursor:'pointer' }}>
          <input
            type="date"
            value={searchDate}
            onChange={(e) => setSearchDate(e.target.value)}
            style={{
              padding: "8px",
              borderRadius: "5px",
              border: "1px solid #ccc",
              width: "100%",
              paddingRight:'30px',
              cursor: 'pointer'
            }}
          />
          {searchDate && (
            <FaTimes
              style={{
                position: "absolute",
                right: "10px",
                top: "50%",
                transform: "translateY(-50%)",
                cursor: "pointer",
                color: "red",
              }}
              onClick={() => setSearchDate("")}
            />
          )}
        </div>
        </div>
        <button
          style={{
            padding: "8px 40px",
            backgroundColor: "#007bff",
            color: "#fff",
            border: "none",
            borderRadius: "5px",
            cursor: "pointer",
          }}
          onClick={() => setFormVisible(true)}
        >
          Add New <FaPlus size={16} />
        </button>
      </div>
      {formVisible && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 1000,
          }}
          onClick={() => setFormVisible(false)}
        >
          <form
            onClick={(e) => e.stopPropagation()}
            onSubmit={handleSubmit}
            style={{
              backgroundColor: "#fff",
              padding: "20px",
              borderRadius: "8px",
              width: "90%",
              maxWidth: "600px",
              maxHeight: "95vh",
              overflowY: "auto",
              boxShadow: "0 2px 8px rgba(0, 0, 0, 0.26)",
            }}
          >
            <h2 style={{ textAlign: "center" }}>Add New Trip Details</h2>
            <input
              type="text"
              name="tripId"
              placeholder="Trip ID"
              value={formData.tripId}
              onChange={(e) => handleInputChange(e, null, setFormData, formData)}
              required
              style={{ width: "100%", padding: "8px", marginBottom: "10px" }}
            />
            <input
              type="text"
              name="name"
              placeholder="Name"
              value={formData.name}
              onChange={(e) => handleInputChange(e, null, setFormData, formData)}
              required
              style={{ width: "100%", padding: "8px", marginBottom: "10px" }}
            />
            <input
              type="text"
              name="phone"
              placeholder="Contact"
              value={formData.phone}
              onChange={(e) => handleInputChange(e, null, setFormData, formData)}
              required
              style={{ width: "100%", padding: "8px", marginBottom: "10px" }}
            />
            <input
              type="email"
              name="email"
              placeholder="Email"
              value={formData.email}
              onChange={(e) => handleInputChange(e, null, setFormData, formData)}
              style={{ width: "100%", padding: "8px", marginBottom: "10px" }}
            />
            <select
              name="admin"
              value={formData.admin}
              onChange={(e) => handleInputChange(e, null, setFormData, formData)}
              required
              style={{ width: "100%", padding: "8px", marginBottom: "10px" }}
            >
              <option value="" disabled>Select Admin</option>
              <option value="sanjeev.katoch@travelunbounded.com">Sanjeev</option>
              <option value="mamta@travelunbounded.com">Mamta</option>
              <option value="ck@travelunbounded.com">Chandrashekar</option>
              <option value="ds@travelunbounded.com">Sai</option>
            </select>
            {formData.dates.map((item, index) => (
              <div key={index} style={{ display: "flex", gap: "10px", flexWrap:'wrap', marginBottom: "10px" }}>
                <input
                  type="date"
                  name="date"
                  value={item.date}
                  onChange={(e) => handleInputChange(e, index, setFormData, formData)}
                  required
                  style={{ flex: 1, padding: "8px" }}
                />
                <input
                  type="text"
                  name="location"
                  placeholder="Location"
                  value={item.location}
                  onChange={(e) => handleInputChange(e, index, setFormData, formData)}
                  required
                  style={{ flex: 1, padding: "8px" }}
                />
                <input
                  type="text"
                  name="resort"
                  placeholder="Resort Name"
                  value={item.resort}
                  onChange={(e) => handleInputChange(e, index, setFormData, formData)}
                  style={{ flex: 1, padding: "8px" }}
                />
                <input
                  type="text"
                  name="vendor"
                  placeholder="vendor Name"
                  value={item.vendor}
                  onChange={(e) => handleInputChange(e, index, setFormData, formData)}
                  style={{ flex: 1, padding: "8px" }}
                />
                <input
                  type="text"
                  name="sinclusions"
                  placeholder="Special Inclusions"
                  value={item.sinclusions}
                  onChange={(e) => handleInputChange(e, index, setFormData, formData)}
                  style={{ flex: 1, padding: "8px" }}
                />
              </div>
            ))}
            <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "10px" }}>
              <button
                type="button"
                onClick={addDatePair}
                style={{
                  padding: "8px 16px",
                  backgroundColor: "#28a745",
                  color: "#fff",
                  border: "none",
                  borderRadius: "5px",
                  cursor: "pointer",
                }}
              >
                Add New Day
              </button>
            </div>
            <button
              type="submit"
              style={{
                width: "100%",
                padding: "10px",
                backgroundColor: "#007bff",
                color: "#fff",
                border: "none",
                borderRadius: "5px",
                cursor: "pointer",
              }}
            >
              Submit
            </button>
          </form>
        </div>
      )}
      <div style={{ maxHeight: "500px", overflowY: "auto" }}>
        <table style={{ width: "100%", borderCollapse: "collapse", marginBottom: "1rem" }}>
          <thead style={{ position: "sticky", top: 0, backgroundColor: "lightblue"}}>
            <tr>
              <th style={{ border: "1px solid #ccc", padding: "10px", textAlign: "left" }}>TripID</th>
              <th style={{ border: "1px solid #ccc", padding: "10px", textAlign: "left" }}>Name</th>
              <th style={{ border: "1px solid #ccc", padding: "10px", textAlign: "left" }}>Contact</th>
              <th style={{ border: "1px solid #ccc", padding: "10px", textAlign: "left" }}>Date</th>
              <th style={{ border: "1px solid #ccc", padding: "10px", textAlign: "left" }}>Location</th>
              <th style={{ border: "1px solid #ccc", padding: "10px", textAlign: "left" }}>Resort</th>
              <th style={{ border: "1px solid #ccc", padding: "10px", textAlign: "left" }}>Vendor</th>
              <th style={{ border: "1px solid #ccc", padding: "10px", textAlign: "left" }}>SP Inclusions</th>
              <th style={{ border: "1px solid #ccc", padding: "10px", textAlign: "left" }}>Email</th>
            </tr>
          </thead>
          <tbody>
            {filteredTrips.map((trip) => (
              <React.Fragment key={trip.id}>
                {trip.dates?.map((dateItem, index) => (
                  <tr key={`${trip.id}-${index}`} >
                    {index === 0 && (
                      <>
                        <td style={{ border: "1px solid #ccc", padding: "10px" }} rowSpan={trip.dates.length}>
                          {editingTripId === trip.tripId ? (
                            <input
                              type="text"
                              value={editData.tripId || trip.tripId}
                              onChange={(e) =>
                                setEditData({ ...editData, tripId: e.target.value })
                              }
                              style={{ width: "100%" }}
                            />
                          ) : (
                            trip.tripId
                          )}
                        </td>
                        <td style={{ border: "1px solid #ccc", padding: "10px" }} rowSpan={trip.dates.length}>
                          {editingTripId === trip.tripId ? (
                            <input
                              type="text"
                              value={editData.name || trip.name}
                              onChange={(e) =>
                                setEditData({ ...editData, name: e.target.value })
                              }
                              style={{ width: "100%" }}
                            />
                          ) : (
                            trip.name
                          )}
                        </td>
                        <td style={{ border: "1px solid #ccc", padding: "10px" }} rowSpan={trip.dates.length}>
                          {editingTripId === trip.tripId ? (
                            <input
                              type="text"
                              value={editData.phone || trip.phone}
                              onChange={(e) =>
                                setEditData({ ...editData, phone: e.target.value })
                              }
                              style={{ width: "100%" }}
                            />
                          ) : (
                            trip.phone
                          )}
                        </td>
                      </>
                    )}
                    <td style={{ border: "1px solid #ccc", padding: "10px", backgroundColor: dateItem.date === searchDate ? "yellow" : "white" }}>
                      {editingTripId === trip.tripId ? (
                        <input
                          type="date"
                          value={
                            (editData.dates && editData.dates[index]?.date) || dateItem.date
                          }
                          onChange={(e) => {
                            const newDates = editData.dates
                              ? [...editData.dates]
                              : [...trip.dates];
                            newDates[index] = { ...newDates[index], date: e.target.value };
                            setEditData({ ...editData, dates: newDates });
                          }}
                          style={{ width: "100%"}}
                        />
                      ) : (
                        dateItem.date
                      )}
                    </td>
                    <td style={{ border: "1px solid #ccc", padding: "10px", backgroundColor: dateItem.date === searchDate ? "yellow" : "white" }}>
                      {editingTripId === trip.tripId ? (
                        <input
                          type="text"
                          value={
                            (editData.dates && editData.dates[index]?.location) || dateItem.location
                          }
                          onChange={(e) => {
                            const newDates = editData.dates
                              ? [...editData.dates]
                              : [...trip.dates];
                            newDates[index] = { ...newDates[index], location: e.target.value };
                            setEditData({ ...editData, dates: newDates });
                          }}
                          style={{ width: "100%" }}
                        />
                      ) : (
                        dateItem.location
                      )}
                    </td>
                    <td style={{ border: "1px solid #ccc", padding: "10px", backgroundColor: dateItem.date === searchDate ? "yellow" : "white" }}>
                      {editingTripId === trip.tripId ? (
                        <input
                          type="text"
                          value={
                            (editData.dates && editData.dates[index]?.resort) || dateItem.resort
                          }
                          onChange={(e) => {
                            const newDates = editData.dates
                              ? [...editData.dates]
                              : [...trip.dates];
                            newDates[index] = { ...newDates[index], resort: e.target.value };
                            setEditData({ ...editData, dates: newDates });
                          }}
                          style={{ width: "100%" }}
                        />
                      ) : (
                        dateItem.resort
                      )}
                    </td>
                    <td style={{ border: "1px solid #ccc", padding: "10px", backgroundColor: dateItem.date === searchDate ? "yellow" : "white" }}>
                      {editingTripId === trip.tripId ? (
                        <input
                          type="text"
                          value={
                            (editData.dates && editData.dates[index]?.vendor) || dateItem.vendor
                          }
                          onChange={(e) => {
                            const newDates = editData.dates
                              ? [...editData.dates]
                              : [...trip.dates];
                            newDates[index] = { ...newDates[index], vendor: e.target.value };
                            setEditData({ ...editData, dates: newDates });
                          }}
                          style={{ width: "100%" }}
                        />
                      ) : (
                        dateItem.vendor
                      )}
                    </td>
                    <td style={{ border: "1px solid #ccc", padding: "10px", backgroundColor: dateItem.date === searchDate ? "yellow" : "white" }}>
                    {editingTripId === trip.tripId ? (
                      <input
                        type="text"
                        value={
                          (editData.dates && editData.dates[index]?.sinclusions) || dateItem.sinclusions
                        }
                        onChange={(e) => {
                          const newDates = editData.dates
                            ? [...editData.dates]
                            : [...trip.dates];
                          newDates[index] = { ...newDates[index], sinclusions: e.target.value };
                          setEditData({ ...editData, dates: newDates });
                        }}
                        style={{ width: "100%" }}
                      />
                    ) : (
                      dateItem.sinclusions
                    )}
                  </td>
                    {index === 0 && (
                      <td style={{ border: "1px solid #ccc", padding: "10px" }} rowSpan={trip.dates.length}>
                        {editingTripId === trip.tripId ? (
                          <input
                            type="email"
                            value={editData.email || trip.email}
                            onChange={(e) =>
                              setEditData({ ...editData, email: e.target.value })
                            }
                            style={{ width: "100%" }}
                          />
                        ) : (
                          trip.email
                        )}
                      </td>
                    )}
                  </tr>
                ))}
                <tr>
                  <td
                    colSpan={4}
                    style={{ border: "1px solid #ccc", padding: "10px" }}
                  >
                    {editingTripId === trip.tripId ? (
                      <>
                        <FaSave
                          style={{ cursor: "pointer", color: "green", marginRight: "15px" }}
                          title="Save"
                          onClick={() => handleEditSave(trip)}
                        />
                        <FaBan
                          style={{ cursor: "pointer", color: "red" }}
                          title="Cancel"
                          onClick={() => {
                            setEditingTripId(null);
                            setEditData({});
                          }}
                        />
                        <select
                        name="admin"
                        value={editData.admin || ""}
                        onChange={(e) => handleInputChange(e, null, setEditData, editData)}
                        required
                        style={{ width: "100%", padding: "5px", marginTop: "5px" }}
                      >
                        <option value="" disabled>Select Admin</option>
                        <option value="sanjeev.katoch@travelunbounded.com">Sanjeev</option>
                        <option value="mamta@travelunbounded.com">Mamta</option>
                        <option value="ck@travelunbounded.com">Chandrashekar</option>
                        <option value="ds@travelunbounded.com">Sai</option>
                      </select>
                      </>
                    ) : (
                      <>
                        <FaEdit
                          style={{ cursor: "pointer", marginRight: "15px" }}
                          title="Edit"
                          onClick={() => {
                            setEditingTripId(trip.tripId);
                            setEditData({ ...trip });
                          }}
                        />
                        <FaTrash
                          style={{ cursor: "pointer", color: "red" }}
                          title="Delete"
                          onClick={() => setDeleteConfirmTripId(trip.tripId)}
                        />
                        <p style={{color: 'green'}}>Admin: {trip.admin}</p>
                      </>
                    )}
                  </td>
                
                {deleteConfirmTripId === trip.tripId && (
                    <td colSpan={5} style={{ textAlign: "center", padding: "10px", backgroundColor: "#fdd" }}>
                      Are you sure you want to delete this trip Details?{" "}
                      <button
                        onClick={() => handleDeleteConfirm(trip)}
                        style={{
                          marginRight: "10px",
                          backgroundColor: "red",
                          color: "#fff",
                          padding: "5px 10px",
                          border: "none",
                          borderRadius: "5px",
                        }}
                      >
                        Yes
                      </button>
                      <button
                        onClick={() => setDeleteConfirmTripId(null)}
                        style={{
                          backgroundColor: "gray",
                          color: "#fff",
                          padding: "5px 10px",
                          border: "none",
                          borderRadius: "5px",
                        }}
                      >
                        No
                      </button>
                    </td>
                )}
                </tr>
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TripTable;
