import React from "react";
import Navbar from "../Navbar";
import Footer from "../Contact";
import "./index.css";
import HomeSlider from "../HomeSlider";
//import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import { HelmetProvider, Helmet } from "react-helmet-async";
import FilterSignature from '../FilterSignature';
//import { ImCross } from "react-icons/im";

const images = [
  "https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/SignatureCollections%2FSpiritual%20Odessey.jpeg?alt=media&token=838ecf77-ca28-4b4f-a7e4-a1a2c71d03d1",
  "https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/SignatureCollections%2FKenya.jpeg?alt=media&token=5166b599-a52c-43fc-bc60-3127bb45909e",
  "https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/SignatureCollections%2FCoorg%20Wilderness%20Resort%20%26%20Spa%20.jpeg?alt=media&token=c7cdeeb9-bf88-4867-a402-f505af5d8de7",
  "https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/SignatureCollections%2FCubs%20RTR%20(1).jpg?alt=media&token=be8d1463-7c6b-49d1-8127-bf3d075950df"
];

const SignaturePackages = () => {
  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>Signature Collections by Travel Unbounded</title>
          <meta
            name="description"
            content="Discover extraordinary Signature Collections with Travel Unbounded. Book Now for an immersive experience"
          />
        </Helmet>
        <Navbar />
        <HomeSlider images={images} showOverlayText={false} />
        <h1 className="text-center signature-package-head mt-5 mb-3">
          Signature Collections
        </h1>
        <FilterSignature />
        <Footer />
      </HelmetProvider>
    </>
  );
};

export default SignaturePackages;