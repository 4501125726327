import React from "react";
//import SouthAfricaCard from "../SouthAfricaCard";
//import WildlifeCard from "../WildlifePackages";
import FilterWildlife from "../FilterWildlife";
import HotSelling from '../HotSelling';
import Navbar from "../Navbar";
import Footer from "../Contact";
import "./packages.css";
import { HelmetProvider, Helmet } from 'react-helmet-async';
import HomeSlider from '../HomeSlider';

const images = [
  "https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/LPackages%2Fpk-5.webp?alt=media&token=90155504-1321-4cd0-b8f1-215f1e8efbae",
  "https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/LPackages%2Fpk-7.webp?alt=media&token=34efc2e7-d892-4497-95d1-65769baf116f",
  "https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d/o/tu-packages%2F9.webp?alt=media&token=090abad3-8d3f-4fde-8fd8-1356a0af79b3",
  "https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d/o/tu-africa%2Fsouth-africa-banner.webp?alt=media&token=e40c56a7-35b2-49b7-941d-b2b543a75658",
];

const HotSellingPackages = () => {
  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>Wildlife Packages by Travel Unbounded</title>
          <meta
            name="description"
            content="Discover extraordinary wildlife with Travel Unbounded. Our expert guides lead tailor-made safari tours, promising moments of awe and wonder. Book now for an immersive journey into nature's wonders."
          />
        </Helmet>
        <Navbar />
        <HomeSlider images={images} showOverlayText={false} />
        <h1 className="text-center signature-package-head mt-5 mb-3">Hot Selling Packages</h1>
        <div className="combined-cards">
          <HotSelling />
        </div>
        <h1 className="text-center signature-package-head mt-3">Wildlife Packages</h1>
        <div className="combined-cards">
          <FilterWildlife />
        </div>
        <Footer />
      </HelmetProvider>
    </>
  );
};

export default HotSellingPackages;
