import React from "react";
import Footer from "../Contact";
import Navbar from "../Navbar";
//import { Link } from "react-router-dom";
//import { IoMdHome } from "react-icons/io";
import { HelmetProvider, Helmet } from 'react-helmet-async';
import "./about.css";

function About() {
  return (
    <>
    <HelmetProvider>
      <Helmet>
        <title>About Us | Travel Unbounded</title>
        <meta
          name="description"
          content="2014 - Founded by K.Chandrashekar, the journey started at a cafe in Bangalore as a proprietary firm. Served 100 Nature loving individuals by designing customised itineraries to various parks in India and Africa."
        />
      </Helmet>
      <Navbar />
      <div className="container">
        <div className="row">
          <div className="about-us-container">
            <h2 className="text-center signature-package-head mb-4">Welcome to Travel Unbounded, your gateway to unforgettable adventures!</h2>
            <p className="about-para">As a dedicated travel company, we believe in authenticity and firsthand experience. We
            promote destinations, resorts, and local experiences only after one of our team members
            has personally explored them. This commitment to genuine exploration sets us apart and
            strengthens our expertise in the destinations we represent, ensuring our clients receive
            nothing less than the best.</p>
            <p className="about-para">We are a leading experiential travel company headquartered at Bangalore . We have our own branch
            offices in Kerala, Tamil Nadu, Goa and in Nairobi, Kenya.</p>
            <p className="about-para">We specialise in crafting customized itineraries that cater to the unique desires of every traveler,
            with a special focus on honeymoon couples, families, large groups and Nature lovers.</p>
            <p className="about-para">From the serene beaches of Bali to the vibrant wildlife of Kenya, we bring exotic locations to life,
            ensuring every journey is a seamless blend of exploration, relaxation, and connection.</p>
            <p className="about-para">We take pride in catering to all ages, making family travel our specialty. From thrilling safaris that
            captivate young explorers to tranquil retreats that allow families to reconnect, we design
            experiences that foster shared memories and inspire a sense of wonder.</p>
            <p className="about-para">Our dedicated team works tirelessly to create personalized experiences that resonate with travelers
            of all ages, resulting in countless happy customers who eagerly share their adventures with friends
            and family.</p>
            <p className="about-para">Join the thousands of satisfied travelers who have embarked on their dream vacations with us and
            returned home not just with souvenirs, but with stories to share and friendships to cherish. Let
            Travel Unbounded be your trusted partner in exploring the world’s most exotic and wild
            destinations, where every trip is a new chapter in your adventure story!</p>

            <h3 className="about-head mt-5">Our Journey</h3>
            <p className="about-para">2014 - Founded by K. Chandrashekar, our journey began in a cozy café in Bangalore, where a
            passion for nature and adventure sparked the creation of our proprietary firm. We initially catered to
            100 nature enthusiasts, designing customized itineraries that took them to some of the most
            stunning parks in India and Africa. This humble beginning ignited a vision to connect travelers with
            the wonders of the natural world, setting the stage for the transformative experiences we offer today.</p>
            <p className="about-para">2015-2016 - Thanks to the enthusiastic word-of-mouth referrals from our guests, we evolved from a
            small venture into a Private Limited company, now proudly serving over 1,000 plus travellers and
            their families. Our expansive offerings include leisure and wilderness holidays across India, Africa,
            Southeast Asia, and the Middle East, allowing us to create unforgettable experiences for
            adventurers of all kinds.</p>
            <p className="about-para">2017- 2018 - We expanded our reach to manage large corporate events in offbeat destinations,
            successfully catering to corporate groups ranging from 100 to 150 attendees per event. Our team
            grew from just 4 to 10 dedicated professionals, allowing us to enhance our offerings for families
            seeking leisure holidays around the globe. Alongside this growth, we remained committed to
            serving nature enthusiasts and wildlife photographers, crafting customized, bespoke journeys that
            immerse them in the beauty of the wild.</p>
            <p className="about-para">2018 - 2020 - We successfully organized large corporate events in offbeat destinations,
            accommodating groups ranging from 150 to 400 participants. Our wildlife journeys to Africa have
            seen remarkable growth, starting with just 19 guests and reaching an impressive 125 during the
            wildebeest migration season alone. Each year, we proudly serve over 500 guests exploring nature
            parks across India and beyond. Our leisure holiday offerings have also expanded significantly,
            establishing us as one of the top travel consultants for Kerala and Himachal tourism. With our
            experienced team now enthusiastically catering to destinations like the Maldives, Sri Lanka, Bali,
            and the Andaman Islands, we continue to create unforgettable experiences for travelers around the
            world.</p>
            <p className="about-para">2020 - 2021 - During the challenging times of the pandemic, we united to support our community.
            As pioneers, we launched a web series featuring renowned wildlife photographers and media
            personalities, sharing their insights with our guests. All proceeds from these events were donated to
            forest guards and their families in need, exemplifying our commitment to those who protect our
            natural world.</p>
            <p className="about-para">We also conducted a very successful Wildlife photography competition called &#39;RIGHT XPOSURE&#39;
            with International judges. We had Discovery channel and Sanctuary Asia as our lead sponsors and
            mentors.</p>
            <p className="about-para">2023 - 2024 - In the aftermath of the pandemic, while many travel companies faced closure, we
            emerged stronger than ever. Although some of our team members returned to their hometowns, we
            renewed our commitment to excellence and quickly adapted to the changing landscape. 2024 has
            been one of our most successful years, particularly in our Africa business, as our leisure travel
            offerings have rebounded impressively. With our delighted customers returning and operations back
            on track, we are excited about the future and ready to create even more unforgettable experiences.</p>
          </div>
        </div>
      </div>
      <Footer />
      </HelmetProvider>
    </>
  );
}
export default About;
