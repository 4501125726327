import React, { useEffect, useState, useRef } from "react";
import { useParams, Link } from "react-router-dom";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import Navbar from "../Navbar";
import "./index.css";
import { HelmetProvider, Helmet } from "react-helmet-async";
import { FaPlus } from "react-icons/fa6";
import { FaMinus } from "react-icons/fa6";
import { FaWhatsapp, FaInstagram } from "react-icons/fa";
import { FaPhoneVolume } from "react-icons/fa6";
import {
  FaYoutube,
  FaPinterest,
  FaFacebookF,
  FaLinkedinIn,
} from "react-icons/fa";
import { AiOutlineMail, AiOutlineCopyrightCircle } from "react-icons/ai";
import { FaMedium } from "react-icons/fa6";
//import {Fade} from 'react-awesome-reveal';
import DummyForm from "../DummyForm";
import { FaCheckCircle, FaTimesCircle } from "react-icons/fa";

const firebaseConfig = {
  apiKey: "AIzaSyB4BPMHKw0OXM2PhQX9NcT25lDBWpRpIPQ",
  authDomain: "phone-auth-d0a8d.firebaseapp.com",
  projectId: "phone-auth-d0a8d",
  storageBucket: "phone-auth-d0a8d.appspot.com",
  messagingSenderId: "309252587015",
  appId: "1:309252587015:web:3f9dc2505965e66269b4a2",
};

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

const resorts = [
  {
    location: "Maasai Mara",
    options: [
      { name: "Interpids,", link: "https://www.guestreservations.com/mara-intrepids-tented-camp/booking?utm_source=google&utm_medium=cpc&utm_campaign=990875490&gad_source=1&gclid=Cj0KCQjw-e6-BhDmARIsAOxxlxWCH6u1iTmLIORTJleoROjAQXfSeSv1njb2Sexn7yzrmaTfZrxzaZIaAvdcEALw_wcB" },
      { name: "Governors Camp", link: "https://governorscamp.com/" },
      { name: "or Similar" },
    ],
  },
  {
    location: "Mombasa",
    options: [
      { name: "Voyager Beach Resort", link: "https://www.heritage-eastafrica.com/voyagerbeachresort/" },
      { name: "or Similar" },
    ],
  },
  {
    location: "Nairobi",
    options: [
      { name: "Argyle Hotel Nairobi", link: "https://www.guestreservations.com/argyle-grand-hotel-nairobi-airport/booking?utm_source=google&utm_medium=cpc&utm_campaign=990875490&gad_source=1&gclid=Cj0KCQjw-e6-BhDmARIsAOxxlxUHwbNVkdgsDcQMW4xRaBfZtSlEeBdgNDrRs7q_9Hz8QKdCX2e09A8aAkevEALw_wcB" },
      { name: "or Similar" },
    ],
  },
];

const resortstan = [
  {
    location: "Tarangire National Park",
    options: [
      { name: "Tarangire Treetops,", link: "https://www.elewanacollection.com/tarangire-treetops/at-a-glance" },
      { name: "Oliver’s Camp", link: "https://www.asiliaafrica.com/camps-lodges/olivers-camp/" },
    ],
  },
  {
    location: "Serengeti National Park",
    options: [
      { name: "Singita Sasakwa Lodge,", link: "https://singita.com/lodge/singita-sasakwa-lodge/" },
      { name: "Four Seasons Safari Lodge Serengeti,", link: "https://www.fourseasons.com/serengeti/?utm_source=google&utm_medium=paidsearch&utm_campaign=saa-sbt-hre-low-brd_hotel_low_sch_rsa_en_ww-na&utm_content=na-na&utm_term=na&gad_source=1&gclid=Cj0KCQjw-e6-BhDmARIsAOxxlxXnI6h4dCKIuIvOXGddY35t1uX226P4xdHgoj6RuBS_dUnrFI_aQ6AaAix4EALw_wcB&gclsrc=aw.ds" },
      { name: "Serengeti Serena Safari Lodge", link: "https://www.serenahotels.com/serengeti" },
    ],
  },
  {
    location: "Ngorongoro Crater",
    options: [
      { name: "&Beyond Ngorongoro Crater Lodge,", link: "https://www.andbeyond.com/our-lodges/africa/tanzania/ngorongoro-crater/andbeyond-ngorongoro-crater-lodge/" },
      { name: "Ngorongoro Serena Safari Lodge,", link: "https://www.serenahotels.com/ngorongoro" },
    ],
  },
  {
    location: "Zanzibar",
    options: [
      { name: "The Residence Zanzibar,", link: "https://www.cenizaro.com/theresidence/zanzibar" },
      { name: "Zanzibar White Sand Luxury Villas & Spa,", link: "https://www.whitesandvillas.com/" },
      { name: "Baraza Resort & Spa Zanzibar", link: "https://www.baraza-zanzibar.com/" },
    ],
  },
];

const resortscentral = [
  {
    park: "Pench",
    boutique: [
      { name: "Tathasu,", link: "https://www.tathasturesorts.com/" },
      { name: "Jamtara", link: "https://www.jamtara.in/" }
    ],
    superLuxury: [
      { name: "Pugdundee Pench Tree Lodge,", link: "https://www.penchtreelodge.com/" },
      { name: "Bhagvan Taj", link: "https://www.tajhotels.com/en-in/hotels/baghvan-pench-national-park" }
    ]
  },
  {
    park: "Bandhavgarh",
    boutique: [
      { name: "Samode Safaris,", link: "https://samode.com/safarilodge/" },
      { name: "Tree Life Ramvilas", link: "https://treeofliferesorts.com/hideaways-resorts/tree-of-life-ramvilas-bandhavgarh-madhya-pradesh/" }
    ],
    superLuxury: [
      { name: "Pugdundee Kings Lodge,", link: "https://www.kingslodge.in/" },
      { name: "Mahua Koti, Taj", link: "https://www.tajhotels.com/en-in/taj/mahua-kothi-bandhavgarh-national-park/" }
    ]
  },
  {
    park: "Kanha",
    boutique: [
      { name: "Kanha Jungle Camp,", link: "https://www.kanhajunglecamp.com/index.html" },
      { name: "Tuli Tiger Reserve,", link: "https://tulitigerresort.com/" },
      { name: "Aryanak", link: "https://www.aranyakresort.com/" }
    ],
    superLuxury: [
      { name: "Pugdundee Kanha Earth Lodge,", link: "https://www.kanhaearthlodge.com/" },
      { name: "Banjaar Tola Taj", link: "https://www.tajhotels.com/en-in/hotels/banjaar-tola-kanha-national-park/" }
    ]
  }
];

const resortsnorth = [
  {
    park: "Corbett",
    boutique: [
      { name: "Namah by Radisson,", link: "https://www.radissonhotels.com/en-us/hotels/radisson-individuals-namah-resort-jim-corbett" },
      { name: "Saraca Resort", link: "https://www.saracahotels.com/corbett/" }
    ],
    superLuxury: [
      { name: "Jim’s Jungle Retreat,", link: "https://www.jimsjungleretreat.com/" },
      { name: "Taj Corbett Resort & SPA", link: "https://www.tajhotels.com/en-in/hotels/taj-corbett-uttarakhand" }
    ]
  },
  {
    park: "Jaipur",
    boutique: [
      { name: "Radisson Jaipur", link: "https://www.radissonhotels.com/en-us/hotels/radisson-jaipur-city-center" }
    ],
    superLuxury: [
      { name: "Taj Amer", link: "https://www.tajhotels.com/en-in/hotels/taj-amer-jaipur" }
    ]
  },
  {
    park: "Ranthambore",
    boutique: [
      { name: "Bagh Vilas,", link: "https://www.baghvillaranthambore.com/" },
      { name: "Aangan", link: "https://aanganresortranthambore.com/" }
    ],
    superLuxury: [
      { name: "Taj Sawai Vilas", link: "https://www.tajhotels.com/en-in/hotels/taj-sawai-vilas-ranthambore" }
    ]
  }
];

const resortsUttarakhand = [
  {
    location: "Haridwar",
    boutique: [
      { name: "Aalia Resort Haridwar", link: "https://www.aaliacollection.com/collection/aalia-ganges-haridwar",  }
    ],
    taj: [
      { name: "Taj Haridwar", link: "https://www.tajhotels.com/en-in/destination/hotels-in-haridwar",  }
    ]
  },
  {
    location: "Rishikesh",
    boutique: [
      { name: "Anand Kashi Rishikesh", link: "https://www.seleqtionshotels.com/en-in/anand-kashi-rishikesh/",  }
    ],
    taj: [
      { name: "Taj Rishikesh Resort & Spa", link: "https://www.tajhotels.com/en-in/hotels/taj-rishikesh",  }
    ]
  }
];

const PackageDetails = () => {
  const { destinationName } = useParams();
  const formattedDestination = destinationName.replace(/-/g, " ");
  const [packageData, setPackageData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [activeIndex, setActiveIndex] = useState(0);
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [showEnquireButton, setShowEnquireButton] = useState(false);
  const sliderRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      if (sliderRef.current) {
        const sliderHeight = sliderRef.current.offsetHeight;
        const scrollPosition = window.scrollY;
        setShowEnquireButton(scrollPosition > sliderHeight);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const openForm = () => {
    setIsFormOpen(true);
  };

  const closeForm = () => {
    setIsFormOpen(false);
  };

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  useEffect(() => {
    const fetchPackage = async () => {
      const db = firebase.firestore();
      try {
        const docRef = db
          .collection("signatureTemplate")
          .doc(formattedDestination);
        const doc = await docRef.get();
        if (doc.exists) {
          setPackageData(doc.data());
        } else {
          console.warn("No package found");
        }
      } catch (error) {
        console.error("Error fetching package:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchPackage();
  }, [formattedDestination]);

  if (isLoading) {
    return (
      <div className="loading-container">
        <p className="loading"></p>
      </div>
    );
  }

  if (!packageData) {
    return <p>No package details available.</p>;
  }

  const {
    title = "",
    description = "",
    destinationHead = "",
    noOfDays = "",
    aboutDestination = [],
    contact = "",
    extrahead = "",
    extrahead2 = "",
    conclusion = "",
    kenya = false,
    tanzania = false,
    centralIndia = false,
    northIndia = false,
    uttarakhand = false,
    email = "",
    shortItinerary = [],
    itineraryTitles = [],
    inclusions = [],
    exclusions = [],
    paymentTerms = [],
    cancellationTerms = [],
    bannerImageUrl = "",
  } = packageData;

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>{title}</title>
          <meta name="description" content={description} />
        </Helmet>
        <Navbar />
        {showEnquireButton && (
          <div className="enquire-buttons-package">
            <div
              className="enquire-button-package enquire-left"
              onClick={openForm}
            >
              <span className="button-text">Enquire Now</span>
            </div>
            {isFormOpen && (
              <div className="">
                <div className="">
                  <DummyForm onClose={closeForm} />
                </div>
              </div>
            )}
          </div>
        )}
        <div className="package-banner-container" ref={sliderRef}>
          {bannerImageUrl && (
            <img
              src={bannerImageUrl}
              alt={destinationName}
              className="package-img"
            />
          )}
        </div>
        <div className="package-container">
          <div className="container mt-4">
            <p
              className="mt-3 package-template-head"
              style={{ color: "#8B4513", fontWeight: "bold" }}
            >
              {destinationHead}
            </p>
            <p
              className="mt-3 package-template-text"
              style={{ color: "#8B4513" }}
            >
              {noOfDays}
            </p>
            <div className="mt-3 package-template-text">
              <span className="overview-head-package">Overview</span> <br />
              {aboutDestination.map((paragraph, index) => (
                <p key={index}>{paragraph}</p>
              ))}
            </div>
            <p
              className="mt-3 mb-3 text-center package-template-head"
              style={{ color: "#8B4513", fontWeight: "bold" }}
            >
              Short Itinerary
            </p>
            <div className="itinerary-container mb-5">
              {shortItinerary.map((item, index) => (
                <div
                  key={index}
                  className={`accordion-item ${activeIndex === index ? "active" : ""}`}
                >
                  <div className="accordion-header" onClick={() => toggleAccordion(index)}>
                    <h5 style={{fontWeight:'bold', fontSize:'20px'}}>{itineraryTitles[index] || `Day ${index + 1}`}</h5>
                    <span>
                      {activeIndex === index ? <FaMinus size={20} /> : <FaPlus size={20} />}
                    </span>
                  </div>
                  {activeIndex === index && (
                    <div className="accordion-body justify">
                      {item.paragraphs.map((paragraphObj, pIndex) => (
                        <div key={pIndex} className="itinerary-item">
                          {paragraphObj.time && (
                            <p className="itinerary-time" style={{ fontWeight: "bold" }}>
                              {paragraphObj.time}
                            </p>
                          )}
                          <p className="itinerary-description">{paragraphObj.paragraph}</p>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              ))}
            </div>

      { kenya && (<div className="container mt-4">
      <div className="table-responsive"> 
        <p className="mb-3 package-template-head"
              style={{ color: "#8B4513", fontWeight: "bold" }}>Resort Options</p>
        <table className="table table-bordered">
          <thead className="table-light">
            <tr>
              <th className="col-6">Location</th>
              <th className="col-6">Resort Options</th>
            </tr>
          </thead>
          <tbody>
            {resorts.map((resort, index) => (
              <tr key={index}>
                <td className="col-6">{resort.location}</td>
                <td className="col-6">
                  {resort.options.map((option, idx) => (
                    <a
                      key={idx}
                      href={option.link}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-primary text-decoration-none me-2"
                    >
                      {option.name}
                    </a>
                  ))}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
      )}


      { tanzania && (<div className="container mt-4">
      <div className="table-responsive">
      <p className="mb-3 package-template-head"
              style={{ color: "#8B4513", fontWeight: "bold" }}>Resort Options</p>
        <table className="table table-bordered resort-table">
          <thead className="table-light">
            <tr>
              <th className="col-6">Location</th>
              <th className="col-6">Resort Options</th>
            </tr>
          </thead>
          <tbody>
            {resortstan.map((resort, index) => (
              <tr key={index}>
                <td className="col-6">{resort.location}</td>
                <td className="col-6">
                  {resort.options.map((option, idx) => (
                    <a
                      key={idx}
                      href={option.link}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-primary text-decoration-none me-2"
                    >
                      {option.name}
                    </a>
                  ))}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div> )}

   { centralIndia && ( <div className="container mt-4">
      <div className="table-responsive">
        <p className="mb-3 package-template-head" style={{ color: "#8B4513", fontWeight: "bold" }}>
          Resort Options
        </p>
        <table className="table table-bordered resort-table">
          <thead className="table-light">
            <tr>
              <th className="col-4">Park</th>
              <th className="col-4">Boutique Resort</th>
              <th className="col-4">Super Luxury</th>
            </tr>
          </thead>
          <tbody>
            {resortscentral.map((resort, index) => (
              <tr key={index}>
                <td className="fw-bold">{resort.park}</td>
                <td>
                  {resort.boutique.map((option, idx) => (
                    <a
                      key={idx}
                      href={option.link}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-primary text-decoration-none d-block"
                    >
                      {option.name}
                    </a>
                  ))}
                </td>
                <td>
                  {resort.superLuxury.map((option, idx) => (
                    <a
                      key={idx}
                      href={option.link}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-primary text-decoration-none d-block"
                    >
                      {option.name}
                    </a>
                  ))}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div> )}

   { northIndia && ( <div className="container mt-4">
      <div className="table-responsive">
        <p className="mb-3 package-template-head" style={{ color: "#8B4513", fontWeight: "bold" }}>
          Resort Options
        </p>
        <table className="table table-bordered resort-table">
          <thead className="table-light">
            <tr>
              <th className="col-4">Park</th>
              <th className="col-4">Boutique Resort</th>
              <th className="col-4">Super Luxury</th>
            </tr>
          </thead>
          <tbody>
            {resortsnorth.map((resort, index) => (
              <tr key={index}>
                <td className="fw-bold">{resort.park}</td>
                <td>
                  {resort.boutique.map((option, idx) => (
                    <a
                      key={idx}
                      href={option.link}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-primary text-decoration-none d-block"
                    >
                      {option.name}
                    </a>
                  ))}
                </td>
                <td>
                  {resort.superLuxury.map((option, idx) => (
                    <a
                      key={idx}
                      href={option.link}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-primary text-decoration-none d-block"
                    >
                      {option.name}
                    </a>
                  ))}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div> )}

   { uttarakhand && ( <div className="container mt-4">
      <div className="table-responsive">
        <p className="mb-3 package-template-head" style={{ color: "#8B4513", fontWeight: "bold" }}>
          Resort Options
        </p>
        <table className="table table-bordered resort-table">
          <thead className="table-light">
            <tr>
              <th className="col-4">Location</th>
              <th className="col-4">Boutique Resort</th>
              <th className="col-4">Taj Properties</th>
            </tr>
          </thead>
          <tbody>
            {resortsUttarakhand.map((resort, index) => (
              <tr key={index}>
                <td className="fw-bold">{resort.location}</td>
                <td>
                  {resort.boutique.map((option, idx) => (
                    <div key={idx}>
                      <a
                        href={option.link}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-primary text-decoration-none d-block"
                      >
                        {option.name}
                      </a>
                    </div>
                  ))}
                </td>
                <td>
                  {resort.taj.map((option, idx) => (
                    <div key={idx}>
                      <a
                        href={option.link}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-primary text-decoration-none d-block"
                      >
                        {option.name}
                      </a>
                    </div>
                  ))}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div> )}


            <div className="container">
              <div className="row inclusion-exclusion-container">
                <div className="col-12 col-md-12">
                  <p className="mt-3 package-template-head inclusion-heading">
                    Inclusions
                  </p>
                  <ul className="inclusion-list">
                    {inclusions.map((item, index) => (
                      <li key={index}>
                        <FaCheckCircle className="icon-package green-icon" />
                        {item || ""}
                      </li>
                    ))}
                  </ul>
                </div>
                {exclusions.length > 0 && (
                <div className="col-12 col-md-12">
                  <p className="mt-3 package-template-head exclusion-heading">
                    Exclusions
                  </p>
                  <ul className="exclusion-list">
                    {exclusions.map((item, index) => (
                      <li key={index}>
                        <FaTimesCircle className="icon-package red-icon" />
                        {item || ""}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
              </div>
            </div>

            <div className="container">
              <div className="row">
                {paymentTerms.length > 0 && (
                  <div className="col-12 col-md-12 col-xl-12 col-lg-12">
                    <p
                      className="mt-3 package-template-head"
                      style={{ color: "#8B4513", fontWeight: "bold" }}
                    >
                      {extrahead}
                    </p>
                    <ul className="list-item-sig">
                      {paymentTerms.map((item, index) => (
                        <li key={index} className="list-item-sig">{item || ""}</li>
                      ))}
                    </ul>
                  </div>
                )}
                {cancellationTerms.length > 0 && (
                  <div className="col-12 col-md-12 col-xl-12 col-lg-12">
                    <p
                      className="mt-3 package-template-head"
                      style={{ color: "#8B4513", fontWeight: "bold" }}
                    >
                      {extrahead2}
                    </p>
                    <ul className="mt-3 list-item-sig">
                      {cancellationTerms.map((item, index) => (
                        <li key={index} className="list-item-sig">{item || ""}</li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="container">
        <div className="row">
        <div className="col-12 col-md-12 col-xl-12 col-lg-12">
        <p
          className=" mb-3 package-template-head"
          style={{ color: "#8B4513", fontWeight: "bold" }}
        >
         Conclusion
        </p>
        <p className="conclusion">{conclusion}</p>
        </div>
        </div>
        </div>
        <div className="enquire-container-book">
          <Link to="/form">
            <button
              className="book-btn-kenya nav-btn-tu-animate"
              style={{ borderRadius: "16px" }}
            >
              Book Now
            </button>
          </Link>
        </div>
        <div className="cancellation-btn-container mb-5 mt-3">
          <Link to="/cancellation-refund-policy" className="cancellation-btn">
            Cancellation & Refund Policy
          </Link>
        </div>
        {/*<Link to='/form' className='link'> 
          <div className='enquire-container-book mt-5 mb-5'>
            <button className='enquire-button-book'>Grab This Exclusive Offer!</button>
          </div>
          </Link> */}
        <div className="package-image-container">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/packageTemplate%2Fimg-2-1.jpg?alt=media&token=0b3c24b4-5369-4297-a276-489954fca122"
            alt="Contact"
            className="background-image-package shake-image"
          />
          <div className="package-contact-wrapper shake-text">
            <div className="package-contact-details">
              <h1 className="text-center">Contact Us</h1>
              <div className="contact-divider"></div>
              <p className="mt-3">
                <FaPhoneVolume /> {contact}
              </p>
              <p>
                <AiOutlineMail /> {email}
              </p>
            </div>
            <div className="package-contact-details">
              <h1 className="text-center">Follow Us</h1>
              <div className="contact-divider"></div>
              <div className="icons-container-footer mt-3">
                <a
                  href="https://www.facebook.com/travelunboundedcom"
                  target="__blank"
                  aria-label="Facebook"
                >
                  <div
                    className="nav-icon-footer text"
                    style={{
                      color: "white",
                      backgroundColor: "#1877F2",
                      marginLeft: "5px",
                    }}
                  >
                    <FaFacebookF size={24} />
                  </div>
                </a>
                <a
                  href="https://www.instagram.com/travel_ub/"
                  target="__blank"
                  aria-label="Instagram"
                >
                  <div
                    className="nav-icon-footer text"
                    style={{
                      color: "white",
                      marginLeft: "5px",
                      transition: "background-color 0.3s ease",
                      background:
                        "radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%)",
                    }}
                  >
                    <FaInstagram size={24} />
                  </div>
                </a>
                <a
                  href="https://www.linkedin.com/company/travel-unbounded/"
                  target="__blank"
                  aria-label="LinkedIn"
                >
                  <div
                    className="nav-icon-footer text"
                    style={{
                      color: "white",
                      marginLeft: "5px",
                      backgroundColor: "#0077B5",
                    }}
                  >
                    <FaLinkedinIn size={24} />
                  </div>
                </a>
                <a
                  href="https://www.youtube.com/@travelunbounded668/"
                  target="__blank"
                  aria-label="YouTube"
                >
                  <div
                    className="nav-icon-footer text"
                    style={{
                      color: "white",
                      marginLeft: "5px",
                      backgroundColor: "#FF0000",
                    }}
                  >
                    <FaYoutube size={24} />
                  </div>
                </a>
                <a
                  href="https://wa.me/917483129148?text=Hi"
                  target="__blank"
                  aria-label="WhatsApp"
                >
                  <div
                    className="nav-icon-footer text"
                    style={{
                      color: "white",
                      backgroundColor: "#25D366",
                      marginLeft: "5px",
                    }}
                  >
                    <FaWhatsapp size={24} />
                  </div>
                </a>
                <a
                  href="https://in.pinterest.com/TUB2014/"
                  target="__blank"
                  aria-label="Pinterest"
                >
                  <div
                    className="nav-icon-footer text"
                    style={{
                      color: "white",
                      backgroundColor: "#E60023",
                      marginLeft: "5px",
                    }}
                  >
                    <FaPinterest size={24} />
                  </div>
                </a>
                <a
                  href="https://medium.com/@Travel_ub"
                  target="__blank"
                  aria-label="Medium"
                >
                  <div
                    className="nav-icon-footer text"
                    style={{
                      color: "white",
                      backgroundColor: "#000000",
                      marginLeft: "5px",
                    }}
                  >
                    <FaMedium size={24} />
                  </div>
                </a>
              </div>
              <p className="margin-tag text-center mt-3">
                <AiOutlineCopyrightCircle /> Travel Unbounded
              </p>
            </div>
          </div>
        </div>
        {/*
        <footer>
        {isFixed ? (
          <div className="fixed-buttons">
            <Link to="/form" className="half-width-link">
              <button className="package-button">Book Now</button>
            </Link>
            <Link to={`/package/${destinationName}/pay-now`} className="half-width-link">
              <button className="package-pay-button">Pay Now</button>
            </Link>
          </div>
        ) : (
          <Link to="/form">
            <button className="package-button full-width">Book Now</button>
          </Link>
        )}
      </footer> */}
      </HelmetProvider>
    </>
  );
};

export default PackageDetails;
