import React from "react";
import Navbar from "../Navbar";
import Footer from "../Contact";
import "./index.css";
import HomeSlider from "../HomeSlider";
//import { IoMdHome } from "react-icons/io";
import { HelmetProvider, Helmet } from "react-helmet-async";
//import DestinationCard from "../DestinationCard";
import Filter from '../Filter';

const images = [
  "https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d/o/tu-packages%2F1.avif?alt=media&token=a1215e26-6c86-42a9-b5a5-000cca923c3d",
  "https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/LPackages%2Fpk-2.webp?alt=media&token=cc33a2fb-250a-49c7-9d2e-6fd1206814c9",
  "https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/LPackages%2Fpk-3.webp?alt=media&token=1a24a1d5-aa8f-41a8-b162-841d659f8c56",
  "https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/LPackages%2Fpk-4.webp?alt=media&token=9f828fc8-1e4c-4541-b27b-3b7e3f880db2",
  "https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/LPackages%2Fpk-6.webp?alt=media&token=09ed993e-1c74-4bc2-9b44-1cc6b5f6c144",
]

const HotSellingPackages = () => {

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>Curated Journeys by Travel Unbounded</title>
          <meta
            name="description"
            content="Discover extraordinary curated journeys with Travel Unbounded. Book Now for an immersive experience"
          />
        </Helmet>
        <Navbar />
        <HomeSlider images={images} showOverlayText={false} />
        <h1 className="text-center signature-package-head mt-5 mb-3">
          Leisure Packages
        </h1>
       <Filter/>
        <Footer />
      </HelmetProvider>
    </>
  );
};

export default HotSellingPackages;
