import React, { useState, useEffect } from "react";
import "react-phone-number-input/style.css";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import { toast, Toaster } from "react-hot-toast";
import "./index.css";
import { FiX } from "react-icons/fi";
//import { IoLogoWhatsapp } from "react-icons/io";
import { FaWhatsapp } from "react-icons/fa";
import { IoMdMail } from "react-icons/io";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
};

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

const CustomForm = ({ onClose }) => {
  const [holidayTypes, setHolidayTypes] = useState([]);
  const [destinations, setDestinations] = useState([]);
  const [loadingHoliday, setLoadingHoliday] = useState(false);
  const [loadingDestination, setLoadingDestination] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    holidayType: "",
    holidayTypeId: "", 
    destination: "",
    destinationId: "",
    email: "",
    phone: "",
    noOfPeople: "",
    kids:"",
    duration:"",
    type: "",
    arrivalDate: "",
  });

  useEffect(() => {
      const fetchHolidayTypes = async () => {
        setLoadingHoliday(true);
        try {
          const response = await fetch(
            "https://prodworkflow.askadmissionsone.in/trxattributes/gettrxoptions?masterId=31",
            {
              method: "GET",
              credentials: "include",
              headers: {
                Authorization:
                  "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJUVSIsIm5hbWUiOiJUcmF2ZWwgVW5ib3VuZGVkIiwiaWF0IjoxNTE2MjM5MDIyfQ.g0Rc16Gcp-E54XxQ6zgSCwbupDIs8KDD0BRasUS3Dx8",
              },
            }
          );
  
          if (!response.ok) {
            throw new Error("Failed to fetch holiday types");
          }
  
          const result = await response.json();
          console.log("Holiday Types API Response:", result);
  
          if (result.data && Array.isArray(result.data)) {
            setHolidayTypes(result.data);
          } else {
            console.error("Invalid API response structure:", result);
            setHolidayTypes([]);
          }
        } catch (error) {
          console.error("Error fetching holiday types:", error);
          setHolidayTypes([]);
        } finally {
          setLoadingHoliday(false);
        }
      };
  
      fetchHolidayTypes();
    }, []);
  
    useEffect(() => {
      if (!formData.holidayTypeId) return; 
  
      const fetchDestinations = async () => {
        setLoadingDestination(true);
        try {
          const response = await fetch(
            `https://prodworkflow.askadmissionsone.in/trxattributes/gettrxoptions?selectedId=${formData.holidayTypeId}`,
            {
              method: "GET",
              credentials: "include",
              headers: {
                Authorization:
                  "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJUVSIsIm5hbWUiOiJUcmF2ZWwgVW5ib3VuZGVkIiwiaWF0IjoxNTE2MjM5MDIyfQ.g0Rc16Gcp-E54XxQ6zgSCwbupDIs8KDD0BRasUS3Dx8",
              },
            }
          );
  
          if (!response.ok) {
            throw new Error("Failed to fetch destinations");
          }
  
          const result = await response.json();
          console.log("Destinations API Response:", result);
  
          if (result.data && Array.isArray(result.data)) {
            setDestinations(result.data);
          } else {
            console.error("Invalid API response structure:", result);
            setDestinations([]);
          }
        } catch (error) {
          console.error("Error fetching destinations:", error);
          setDestinations([]);
        } finally {
          setLoadingDestination(false);
        }
      };
  
      fetchDestinations();
    }, [formData.holidayTypeId]);
  
    const handleChange = (e) => {
      const { name, value } = e.target;
    
      if (name === "holidayType") {
        const selectedHoliday = holidayTypes.find((ht) => ht.Label === value);
        setFormData((prevData) => ({
          ...prevData,
          holidayType: value,
          holidayTypeId: selectedHoliday ? selectedHoliday.TAOCSVInstId : null, 
          destination: "", 
          destinationId: null, 
        }));
      } else if (name === "destination") {
        const selectedDestination = destinations.find((d) => d.Label === value);
        setFormData((prevData) => ({
          ...prevData,
          destination: value,
          destinationId: selectedDestination ? selectedDestination.TAOCSVInstId : null, 
        }));
      } else {
        setFormData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      }
    };

  const handleSubmit = async (e) => {
      e.preventDefault();
  
      try {
        const {
          name,
          email,
          phone,
          holidayType,
          holidayTypeId,
          destinationId,
          destination,
          noOfPeople,
          kids,
          arrivalDate,
          type,
          duration,
        } = formData;
  
        const db = firebase.firestore();
        await db.collection("EnquireFormTU").add({
          name: name,
          email: email,
          phone: phone,
          holidayType: holidayType,
          destination: destination,
          noOfPeople: noOfPeople,
          kids: kids,
          arrivalDate: arrivalDate,
          type: type,
          duration: duration,
          timestamp: firebase.firestore.FieldValue.serverTimestamp(),
        });
  
        const response1 = await fetch(
          "https://formbackend.netlify.app/enquire-now",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              name,
              email,
              phone,
              holidayType,
              destination,
              noOfPeople,
              kids,
              arrivalDate,
              type,
              duration,
            }),
          }
        );
  
        const data1 = await response1.json();
        console.log("First API response:", data1.message);
  
        const response2 = await fetch(
          "https://prodcron.askadmissionsone.in/external/carrierv2/travel-unbounded/39",
          {
            method: "POST",
            headers: {
              authorization:
                "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhdXRoY29kZSI6InRyYXZlbC11bmJvdW5kZWQiLCJleGFtSWQiOiIzOSIsImlhdCI6MTUxNjIzOTAyMn0.5jL0vXxSikx9Ql3wYY7o-JAs6RrQ7m0250j0BRBa2Rc",
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              CategoryType: holidayTypeId, 
              Category: destinationId, 
              "No. of Adults": noOfPeople,
              "Kids above 5 yrs": kids,
              "Travel Date": arrivalDate,
              "Stay Preference": type,
              "Stay Duration": duration,
              name,
              phone: '+91' + phone, 
              Email: email,
              "Traveller Name": name, 
            }),
          }
        );
        
        const data2 = await response2.json();
        console.log("Second API response:", data2);
        setFormData({
          name: "",
          email: "",
          phone:"",
          holidayType: "",
          destination: "",
          productLineId: "",
          categoryId: "",
          noOfPeople: "",
          kids: "",
          arrivalDate: "",
          type: "",
          duration: "",
        });
        toast.success("Enquiry Submitted Successful");
        onClose();
      } catch (error) {
        console.error("Error submitting form:", error);
        toast.error("Error submitting Enquiry. Please try again.");
      }
    };

  const handleEnquire = () => {
    const whatsappNumber = "917483129148";
    const message = "Hi";
    const whatsappLink = `https://wa.me/${whatsappNumber}?text=${encodeURIComponent(
      message
    )}`;
    window.open(whatsappLink, "_blank");
  };

  return (
    <>
      <Toaster
        toastOptions={{ duration: 5000 }}
      />
      <div className="form-wrapper">
        <div
          className="info-section d-none d-md-block"
          style={{ background: "rgb(202, 218, 218)" }}
        >
          <div className="info-col">
            <div>
              <h4 className="details">How it works</h4>
              <p className="details">
                1. Provide us with the details of your holiday plans.
              </p>
              <p className="details">
                2. Our travel experts will curate the itinerary based on your
                preferences and send you the best suited tour quote.
              </p>
              <p className="details">
                3. Pick the best deal and finalize your booking.
              </p>
            </div>
            <div>
              <p className="contact-number">
                <FaWhatsapp size={20} />
                <a
                  href="https://wa.me/917483129148?text=Hi"
                  target="__blank"
                  style={{ color: "black" }}
                >
                  {" "}
                  Say 'Hi' & Enquire on Whatsapp
                </a>
              </p>
              <p className="contact-number">
                <IoMdMail size={20} /> support@travelunbounded.com
              </p>
            </div>
          </div>
        </div>
        <div className="form-section">
          <button
            className="close-icon-form"
            onClick={onClose}
            aria-label="Close"
          >
            <FiX size={20} />
          </button>
          <h2 className="form-title blinking-price" style={{ color: "green" }}>
            Enquire Now
          </h2>
          <form onSubmit={handleSubmit}>
            <input
              type="text"
              name="name"
              placeholder="Name"
              className="form-input"
              value={formData.name}
              onChange={handleChange}
              required
            />
            <input
              type="email"
              name="email"
              placeholder="Email"
              className="form-input"
              value={formData.email}
              onChange={handleChange}
              required
            />

            <input
              type="text"
              name="phone"
              placeholder="Phone Number"
              className="form-input"
              value={formData.phone}
              onChange={handleChange}
              required
            />

            <select
              className="form-input"
              name="holidayType"
              value={formData.holidayType || ""}
              onChange={handleChange}
              required
            >
            <option value="" disabled>
              {loadingHoliday ? "" : "Choose Holiday Type"}
            </option>
            {holidayTypes.length > 0 ? (
              holidayTypes.map((holiday) => (
                <option key={holiday.TAOCSVInstId} value={holiday.Label}>
                  {holiday.Label}
                </option>
              ))
            ) : (
              !loadingHoliday && <option disabled>No holiday types available</option>
              )}
            </select>

            <select
              className="form-input"
              name="destination"
              value={formData.destination || ""}
              onChange={handleChange}
              required
              disabled={!formData.holidayTypeId}
            >
            <option value="" disabled>
              {loadingDestination ? "" : "Choose Destination"}
            </option>
            {destinations.length > 0 ? (
              destinations.map((destination) => (
            <option key={destination.TAOCSVInstId} value={destination.Label}>
              {destination.Label}
            </option>
            ))
            ) : (
              !loadingDestination && <option disabled>No destinations available</option>
            )}
            </select>

            <input
              type="number"
              name="noOfPeople"
              placeholder="No.of Adults"
              className="form-input"
              value={formData.noOfPeople}
              onChange={handleChange}
              required
            />
             <input
              type="number"
              name="kids"
              placeholder="No.of Kids (>5yrs)"
              className="form-input"
              value={formData.kids}
              onChange={handleChange}
              required
            />
            <input
              type="text"
              name="arrivalDate"
              className="form-input"
              placeholder="Arrival Date"
              onFocus={(e) => {
                e.target.type = "date";
                e.target.showPicker();
              }}
              onBlur={(e) => {
                if (e.target.value === "") {
                  e.target.type = "text";
                }
              }}
              value={formData.arrivalDate}
              onChange={handleChange}
              required
            />
              <select
                className="form-input"
                name="type"
                value={formData.type}
                onChange={handleChange}
                required
              >
              <option value="" disabled>
              Choose Stay Preference
              </option>
              <option value="3 star">3 Star</option>
              <option value="4 star">4 Star</option>
              <option value="5 star">5 Star</option>
              </select>
              <select
                className="form-input"
                name="duration"
                value={formData.duration}
                onChange={handleChange}
                required
              >
              <option value="" disabled>
                Choose Stay Duration
              </option>
              <option value="2 Nights">2 Nights</option>
              <option value="3 Nights">3 Nights</option>
              <option value="4 Nights">4 Nights</option>
              <option value="5+ Nights">Other</option>
            </select>
            
            <button className="submit-button">
              Submit
            </button>
            <button
              className="submit-button-whatsapp d-none d-md-block"
              onClick={handleEnquire}
            >
              <FaWhatsapp size={20} /> Need Live assistance Chat Now
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default CustomForm;
