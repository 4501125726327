import React, { useState, useEffect } from "react";
import { FaBars, FaTimes, FaChevronDown, FaChevronUp } from "react-icons/fa";
import { Link, NavLink, useLocation } from "react-router-dom";
import { MdHome, MdPlace, MdAddCall } from "react-icons/md";
import { LuPackagePlus } from "react-icons/lu";
import { FaEarthAfrica } from "react-icons/fa6";
import { FaBlog } from "react-icons/fa";
import { MdContactSupport } from "react-icons/md";
import { FaFileSignature } from "react-icons/fa";
import "./nav.css";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const location = useLocation();

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  useEffect(() => {
    setIsDropdownOpen(false);
    setIsOpen(false);
  }, [location.pathname]);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (!event.target.closest(".dropdown-tu, .sidebar-tu")) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  const destinationRoutes = [
    "/kerala",
    "/himachal",
    "/bandipur",
    "/kabini",
    "/corbett",
    "/ranthambore",
    "/bandhavgarh",
    "/kenya",
    "/vietnam",
    "/tanzania",
    "/andaman",
    "/iceland",
    "/bali",
    "/nepal",
    "/bhutan",
    "/turkey",
    "/greece",
    "/switzerland",
    "/dubai",
    "/singapore",
    "/malaysia",
    "/netherlands",
  ];
  const blogRoutes = ["/blog/"];

  const leisurePackages = [
    "kerala",
    "spiti",
    "lahaul",
    "vietnam",
    "iceland",
    "shimla",
    "andaman",
    "nepal",
    "bhutan",
    "bali",
    "Kathmandu",
    "greece",
    "greek",
    "dubai",
    "singapore",
    "turkey",
    "netherlands",
    "malaysia",
    "switzerland",
    "new"
  ];
  const wildlifePackages = [
    "bandipur",
    "bandhavgarh",
    "corbett",
    "kabini",
    "ranthambore",
    "kenya",
    "pench",
    "satpura",
    "south-africa",
    "migration"
  ];

  const signatureCollections = [

  ];

  const currentPath = location.pathname.toLowerCase();

  const matchPackageInPath = (packagesArray) => {
    return packagesArray.some(
      (pkg) =>
        currentPath.startsWith("/package/") &&
        currentPath.includes(pkg.toLowerCase())
    );
  };

  const isLeisureActive = matchPackageInPath(leisurePackages);
  const isWildlifeActive = matchPackageInPath(wildlifePackages);
  const isSignature = matchPackageInPath(signatureCollections);

  const isBlogActive = blogRoutes.some((route) =>
    location.pathname.startsWith(route)
  );
  const isDestinationsActive = destinationRoutes.includes(location.pathname);

  return (
    <header className="navbar-tu">
      <div className="logo-tu">
        <Link to="/">
          <img src="/logo.webp" alt="logo" className="logo-img-tu" />
        </Link>
      </div>
      <div>
        <ul className="nav-items-tu">
          <li>
            <NavLink to="/" className="nav-tu-link" activeclassname="active">
              HOME
            </NavLink>
          </li>
          <li
            className={`dropdown-tu ${isDestinationsActive ? "active" : ""}`}
            onClick={(e) => {
              e.stopPropagation();
              setIsDropdownOpen(!isDropdownOpen);
            }}
          >
            <div className="dropdown-head" onClick={toggleDropdown}>
              DESTINATIONS
              {isDropdownOpen ? (
                <FaChevronUp className="dropdown-icon" />
              ) : (
                <FaChevronDown className="dropdown-icon" />
              )}
            </div>
            {isDropdownOpen && (
              <div className="dropdown-content-tu">
                <div className="dropdown-row">
                  <div className="dropdown-column">
                    <h4 className="text-center">India</h4>
                    <ul>
                      <li>
                        <NavLink
                          to="/kerala"
                          className="nav-tu-link"
                          activeclassname="active"
                        >
                          Kerala
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to="/himachal"
                          className="nav-tu-link"
                          activeclassname="active"
                        >
                          Himachal
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to="/andaman"
                          className="nav-tu-link"
                          activeclassname="active"
                        >
                          Andaman
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to="/bandipur"
                          className="nav-tu-link"
                          activeclassname="active"
                        >
                          Bandipur
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to="/kabini"
                          className="nav-tu-link"
                          activeclassname="active"
                        >
                          Kabini
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to="/corbett"
                          className="nav-tu-link"
                          activeclassname="active"
                        >
                          Corbett
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to="/ranthambore"
                          className="nav-tu-link"
                          activeclassname="active"
                        >
                          Ranthambore
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to="/bandhavgarh"
                          className="nav-tu-link"
                          activeclassname="active"
                        >
                          Bandhavgarh
                        </NavLink>
                      </li>
                    </ul>
                  </div>
                  <div className="dropdown-column">
                    <h4 className="text-center">International</h4>
                    <ul>
                      <li>
                        <NavLink
                          to="/kenya"
                          className="nav-tu-link"
                          activeclassname="active"
                        >
                          Kenya
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to="/package/wildlife/magical-kenya"
                          className="nav-tu-link"
                          activeclassname="active"
                        >
                          Magical Kenya
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to="/vietnam"
                          className="nav-tu-link"
                          activeclassname="active"
                        >
                          Vietnam
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to="/bali"
                          className="nav-tu-link"
                          activeclassname="active"
                        >
                          Bali
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to="/nepal"
                          className="nav-tu-link"
                          activeclassname="active"
                        >
                          Nepal
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to="/bhutan"
                          className="nav-tu-link"
                          activeclassname="active"
                        >
                          Bhutan
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to="/iceland"
                          className="nav-tu-link"
                          activeclassname="active"
                        >
                          Iceland
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to="/tanzania"
                          className="nav-tu-link"
                          activeclassname="active"
                        >
                          Tanzania
                        </NavLink>
                      </li>
                    </ul>
                  </div>
                  <div className="dropdown-column">
                    <ul className="mt-4">
                      <li>
                        <NavLink
                          to="/singapore"
                          className="nav-tu-link"
                          activeclassname="active"
                        >
                          Singapore
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to="/greece"
                          className="nav-tu-link"
                          activeclassname="active"
                        >
                          Greece
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to="/turkey"
                          className="nav-tu-link"
                          activeclassname="active"
                        >
                          Turkey
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to="/dubai"
                          className="nav-tu-link"
                          activeclassname="active"
                        >
                          Dubai
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to="/malaysia"
                          className="nav-tu-link"
                          activeclassname="active"
                        >
                          Malaysia
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to="/switzerland"
                          className="nav-tu-link"
                          activeclassname="active"
                        >
                          Switzerland
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to="/netherland"
                          className="nav-tu-link"
                          activeclassname="active"
                        >
                          Netherland
                        </NavLink>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            )}
          </li>
          <li>
            <NavLink
              to="/leisure-packages"
              className={`nav-tu-link ${isLeisureActive ? "active" : ""}`}
              activeclassname="active"
            >
              LEISURE PACKAGES
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/wildlife-packages"
              className={`nav-tu-link ${isWildlifeActive ? "active" : ""}`}
            >
              WILDLIFE PACKAGES
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/signature-collections"
              className={`nav-tu-link ${isSignature ? "active" : ""}`}
            >
              SIGNATURE COLLECTIONS
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/blogs"
              className={`nav-tu-link ${isBlogActive ? "active" : ""}`}
              activeclassname="active"
            >
              BLOGS
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/about"
              className="nav-tu-link"
              activeclassname="active"
            >
              ABOUT US
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/contact"
              className="nav-tu-link"
              activeclassname="active"
            >
              CONTACT US
            </NavLink>
          </li>
        </ul>
      </div>
      <div className="nav-btn-container-tu">
        <Link to="/form" className="nav-tu-link">
          <button
            type="button"
            className="nav-btn-tu-animate"
            aria-label="Book Now"
          >
            <span className="text">Book Now</span>
          </button>
        </Link>
      </div>
      <div className="menu-icon-tu-nav" onClick={toggleSidebar}>
        {isOpen ? (
          <FaTimes className="menu-icon-nav" />
        ) : (
          <FaBars className="menu-icon-nav" />
        )}
      </div>
      <nav className={`sidebar-tu ${isOpen ? "open" : ""}`}>
        <ul>
          <li>
            <NavLink to="/" className="nav-tu-link" activeclassname="active">
              <MdHome size={20} style={{ marginRight: "5px" }} />{" "}
              <span style={{ paddingLeft: "10px" }}>HOME</span>
            </NavLink>
          </li>
          <hr />
          <li className={`dropdown-tu ${isDestinationsActive ? "active" : ""}`}>
            <div className="dropdown-head" onClick={toggleDropdown}>
              <MdPlace size={20} />{" "}
              <span style={{ paddingLeft: "10px" }}>DESTINATIONS</span>
              {isDropdownOpen ? (
                <FaChevronUp className="dropdown-icon" />
              ) : (
                <FaChevronDown className="dropdown-icon" />
              )}
            </div>
            {isDropdownOpen && (
              <>
                <h4 className="text-center head-nav mt-3">India</h4>
                <div className="sidebar-dropdown-content">
                  <div className="sidebar-dropdown-column">
                    <ul>
                      <li>
                        <NavLink
                          to="/kerala"
                          className="nav-tu-link"
                          activeclassname="active"
                        >
                          Kerala
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to="/himachal"
                          className="nav-tu-link"
                          activeclassname="active"
                        >
                          Himachal
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to="/andaman"
                          className="nav-tu-link"
                          activeclassname="active"
                        >
                          Andaman
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to="/bandipur"
                          className="nav-tu-link"
                          activeclassname="active"
                        >
                          Bandipur
                        </NavLink>
                      </li>
                    </ul>
                  </div>
                  <div className="sidebar-dropdown-column">
                    <ul>
                      <li>
                        <NavLink
                          to="/kabini"
                          className="nav-tu-link"
                          activeclassname="active"
                        >
                          Kabini
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to="/corbett"
                          className="nav-tu-link"
                          activeclassname="active"
                        >
                          Corbett
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to="/ranthambore"
                          className="nav-tu-link"
                          activeclassname="active"
                        >
                          Ranthambore
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to="/bandhavgarh"
                          className="nav-tu-link"
                          activeclassname="active"
                        >
                          Bandhavgarh
                        </NavLink>
                      </li>
                    </ul>
                  </div>
                </div>
                <h4 className="text-center head-nav mt-3">International</h4>
                <div className="sidebar-dropdown-content mt-3">
                  <div className="sidebar-dropdown-column">
                    <ul>
                      <li>
                        <NavLink
                          to="/kenya"
                          className="nav-tu-link"
                          activeclassname="active"
                        >
                          Kenya
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to="/package/wildlife/magical-kenya"
                          className="nav-tu-link"
                          activeclassname="active"
                        >
                          Magical Kenya
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to="/vietnam"
                          className="nav-tu-link"
                          activeclassname="active"
                        >
                          Vietnam
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to="/bali"
                          className="nav-tu-link"
                          activeclassname="active"
                        >
                          Bali
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to="/nepal"
                          className="nav-tu-link"
                          activeclassname="active"
                        >
                          Nepal
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to="/bhutan"
                          className="nav-tu-link"
                          activeclassname="active"
                        >
                          Bhutan
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to="/iceland"
                          className="nav-tu-link"
                          activeclassname="active"
                        >
                          Iceland
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to="/tanzania"
                          className="nav-tu-link"
                          activeclassname="active"
                        >
                          Tanzania
                        </NavLink>
                      </li>
                    </ul>
                  </div>
                  <div className="sidebar-dropdown-column">
                    <ul>
                      <li>
                        <NavLink
                          to="/singapore"
                          className="nav-tu-link"
                          activeclassname="active"
                        >
                          Singapore
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to="/greece"
                          className="nav-tu-link"
                          activeclassname="active"
                        >
                          Greece
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to="/turkey"
                          className="nav-tu-link"
                          activeclassname="active"
                        >
                          Turkey
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to="/dubai"
                          className="nav-tu-link"
                          activeclassname="active"
                        >
                          Dubai
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to="/switzerland"
                          className="nav-tu-link"
                          activeclassname="active"
                        >
                          Switzerland
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to="/netherland"
                          className="nav-tu-link"
                          activeclassname="active"
                        >
                          Netherland
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to="/malaysia"
                          className="nav-tu-link"
                          activeclassname="active"
                        >
                          Malaysia
                        </NavLink>
                      </li>
                    </ul>
                  </div>
                </div>
              </>
            )}
          </li>
          <hr />
          <li>
            <NavLink
              to="/leisure-packages"
              className={`nav-tu-link ${isLeisureActive ? "active" : ""}`}
              activeclassname="active"
            >
              <LuPackagePlus size={20} />{" "}
              <span style={{ paddingLeft: "10px" }}>LEISURE PACKAGES</span>
            </NavLink>
          </li>
          <hr />
          <li>
            <NavLink
              to="/wildlife-packages"
              className={`nav-tu-link ${isWildlifeActive ? "active" : ""}`}
              activeclassname="active"
            >
              <FaEarthAfrica size={20} />{" "}
              <span style={{ paddingLeft: "10px" }}> WILDLIFE PACKAGES</span>
            </NavLink>
          </li>
          <hr />
          <li>
            <NavLink
              to="/signature-collections"
              className={`nav-tu-link ${isSignature ? "active" : ""}`}
              activeclassname="active"
            >
              <FaFileSignature size={20} />{" "}
              <span style={{ paddingLeft: "10px" }}> SIGNATURE COLLECTIONS</span>
            </NavLink>
          </li>
          <hr />
          <li>
            <NavLink
              to="/blogs"
              className={`nav-tu-link ${isBlogActive ? "active" : ""}`}
              activeclassname="active"
            >
              <FaBlog size={20} />{" "}
              <span style={{ paddingLeft: "10px" }}>BLOGS</span>
            </NavLink>
          </li>
          <hr />
          <li>
            <NavLink
              to="/about"
              className="nav-tu-link"
              activeclassname="active"
            >
              <MdContactSupport size={20} />{" "}
              <span style={{ paddingLeft: "10px" }}> ABOUT US</span>
            </NavLink>
          </li>
          <hr />
          <li>
            <NavLink
              to="/contact"
              className="nav-tu-link"
              activeclassname="active"
            >
              <MdAddCall size={20} />{" "}
              <span style={{ paddingLeft: "10px" }}>CONTACT US</span>
            </NavLink>
          </li>
          <hr />
          <div className="btn-flex-nav">
            <Link to="/form" className="nav-tu-link">
              <button
                type="button"
                className="nav-btn-tu-animate-mobile"
                aria-label="Book Now"
              >
                <span className="text">Book Now</span>
              </button>
            </Link>
          </div>
        </ul>
      </nav>
    </header>
  );
};

export default Navbar;
