import React, { useState } from "react";
import "./index.css";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import { toast, Toaster } from "react-hot-toast";
import { FaBars } from "react-icons/fa";

const firebaseConfig = {
  apiKey: "AIzaSyB4BPMHKw0OXM2PhQX9NcT25lDBWpRpIPQ",
  authDomain: "phone-auth-d0a8d.firebaseapp.com",
  projectId: "phone-auth-d0a8d",
  storageBucket: "phone-auth-d0a8d.appspot.com",
  messagingSenderId: "309252587015",
  appId: "1:309252587015:web:3f9dc2505965e66269b4a2"
};

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

const Sidebar = ({ isSidebarOpen, setActiveComponent, setIsSidebarOpen, activeComponent }) => {
  const [isCollapsed, setIsCollapsed] = useState(false);

  const handleItemClick = (component) => {
    setActiveComponent(component);
    setIsSidebarOpen(false);
  };

  const handleConfirmLogout = () => {
    firebase.auth()
      .signOut()
      .then(() => {
        console.log("Logged Out Successfully");
        toast.success("Logged Out Successfully");
      })
      .catch((error) => {
        console.error("Error logging out:", error);
        toast.error("Logout Failed");
      });
  };

  return (
    <>
      <Toaster toastOptions={{ duration: 5000 }} />
      <div className={`sidebar-dashboard ${isCollapsed ? "collapsed" : ""}`}>
        <div className="sidebar-header">
           <button className="hamburger-menu" onClick={() => setIsCollapsed(!isCollapsed)}>
            <FaBars />
          </button>
          {!isCollapsed && (
            <div className="sidebar-logo-dashboard">
              <img src="/logo.png" alt="Logo" onClick={() => handleItemClick("HomeDashboard")} />
            </div>
          )}
         
        </div>
        {!isCollapsed && (
          <>
            <ul>
              <li className={activeComponent === "HomeDashboard" ? "active" : ""} onClick={() => handleItemClick("HomeDashboard")}>
                Dashboard Home
              </li>
              <li className={activeComponent === "CustomerDetails" ? "active" : ""} onClick={() => handleItemClick("CustomerDetails")}>
                Leisure Trip Sheet
              </li>
              <li className={activeComponent === "WildlifeCustomerDetails" ? "active" : ""} onClick={() => handleItemClick("WildlifeCustomerDetails")}>
                Wildlife Trip Sheet
              </li>
              <li className={activeComponent === "InternationalCustomerDetails" ? "active" : ""} onClick={() => handleItemClick("InternationalCustomerDetails")}>
                International Trip Sheet
              </li>
              <li className={activeComponent === "AllBookings" ? "active" : ""} onClick={() => handleItemClick("AllBookings")}>
                View Bookings
              </li>
              <li className={activeComponent === "AllEnquiries" ? "active" : ""} onClick={() => handleItemClick("AllEnquiries")}>
                View Enquiries
              </li>
              
              <li className={activeComponent === "DestinationTemplate" ? "active" : ""} onClick={() => handleItemClick("DestinationTemplate")}>
                Add Destination
              </li>
              <li className={activeComponent === "AddPackages" ? "active" : ""} onClick={() => handleItemClick("AddPackages")}>
                Add Leisure Packages
              </li>
              <li className={activeComponent === "AddWildlifePackages" ? "active" : ""} onClick={() => handleItemClick("AddWildlifePackages")}>
                Add Wildlife Packages
              </li>
              <li className={activeComponent === "AddSignaturePackages" ? "active" : ""} onClick={() => handleItemClick("AddSignaturePackages")}>
                Add Signature Collections
              </li>
              <li className={activeComponent === "AddMediumBlog" ? "active" : ""} onClick={() => handleItemClick("AddMediumBlog")}>
                Add Medium Blog
              </li>
            </ul>
            <button className="logout-btn-dashboard" onClick={handleConfirmLogout}>
              Logout
            </button>
          </>
        )}
      </div>
    </>
  );
};

export default Sidebar;