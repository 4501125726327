import React, { useState, useEffect } from 'react';
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { Link } from 'react-router-dom';
import './index.css';

const Packages = () => {
  const [packages, setPackages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);


  useEffect(() => {
    const fetchPackages = async () => {
      setIsLoading(true);
      const db = firebase.firestore();
      try {
        const snapshot = await db.collection("signatureTemplate").get();
        const packageList = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setPackages(packageList);
      } catch (error) {
        console.error("Error fetching packages:", error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchPackages();
  }, []);

  return (
    <div className="packages-container-search">
      <div className="destinations-container">
        {isLoading ? (
          <div className='loading-container'>
            <p className="loading"></p>
          </div>
        ) : packages.length > 0 ? (
          <div className="best-package-list">
            {packages.map((pkg) => (
              <div key={pkg.id} className="package-list-card mb-3">
                <div className="image-container">
                  <img
                    src={pkg.bannerImageUrl}
                    alt={pkg.destinationName}
                    className="list-card-img mb-3"
                  />
                  {pkg.recommend && (
                    <span className="recommend-tag">{pkg.tag}</span>
                  )}
                </div>
                <div>
                  <p className="title-list mb-3" style={{fontStyle:'italic'}}>{pkg.destinationHead}</p>
                  <p className="title-dates-list bold mb-3" style={{fontStyle:'italic'}}>{pkg.noOfDays}</p>
                  
                  <div className="btn-container-list-card">
                    <Link
                      to={`/signature-collections/${pkg.destinationName.replace(/\s+/g, "-")}`}
                      className="blogs-link"
                    >
                      <button className="view-button-list-card">
                        View Itinerary
                      </button>
                    </Link>
                    <Link to="/form" className="blogs-link">
                      <button className="view-button-list-card-book">
                        Book Now
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className='search-container-notfound'>
            <p className="no-results-search">No packages found for the selected destination.</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default Packages;
