import React, { useState, useEffect, useRef } from 'react';
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { Link } from 'react-router-dom';
import axios from 'axios'
import './index.css';

const destinations = [
  { name: 'All', image: '/infy-logo.jpg'},
  { name: 'Kenya', image: 'https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/kenya%2Fkenya-1-1.webp?alt=media&token=d48b8b69-2f80-42fd-877d-6e49c3bfeca6' },
  { name: 'Corbett', image: 'https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/WildlifePackageTemplate%2FParwali%20Jr.jpg?alt=media&token=17e9d5cc-5613-4cc0-9b50-6083a928e8ee' },
  { name: 'Bandhavgarh', image: 'https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/WildlifePackageTemplate%2FBandhavgarh_banner_image_weibkp_j7ugcf.jpg?alt=media&token=b433215a-d041-4cda-a180-3f0dfc78b561' },
  { name: 'Bandipur', image: 'https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/WildlifePackageTemplate%2FTiger_Bandipur_1_k4efwb_glxrm0.jpg?alt=media&token=1831a7fa-1a79-44a7-bdde-bd420a9bd138' },
  { name: 'Kabini', image: 'https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/kabini%2Fkabini-5.webp?alt=media&token=60eb30c9-02d2-4dc1-8f98-055c9452907e' },
  { name: 'Ranthambore', image: 'https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/WildlifePackageTemplate%2FRanthambore_Gallery_2_ckxejx_ef5xr4.jpg?alt=media&token=4fde1700-d4a8-45a8-a2ff-5c18636dd70d' },
  { name: 'Pench', image: 'https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/WildlifePackageTemplate%2FSambhar%20Pench.jpg?alt=media&token=9d00c6f4-077f-4ab9-a680-29f3336e9712' },
  {name: 'Tadoba', image:'https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/tadoba%2FTATR%20tigress.jpg?alt=media&token=37a38d5a-4261-45fc-b4be-272fcbd97e31'},
  {name: 'Satpura', image:'https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/LPackages%2Fsatpura.webp?alt=media&token=5eab323a-69f6-45e7-afad-fc087225a241'},
  { name: 'SouthAfrica', image: 'https://firebasestorage.googleapis.com/v0/b/phone-auth-d0a8d.appspot.com/o/TU-Africa%2Fa-1-new.jpeg?alt=media&token=92be02a0-855b-48b9-beb5-49c607d9b026' },
];

const destinationKeywords = {
  Kenya: ['kenya', 'migration'],
  Corbett: ['corbett'],
  Bandhavgarh: ['bandhavgarh'],
  Bandipur: ['bandipur'],
  Kabini: ['kabini'],
  Ranthambore: ['ranthambore'],
  Pench:['pench'],
  Tadoba:['tadoba'],
  Satpura:['satpura'],
  SouthAfrica:['kruger']
};

const Packages = () => {
  const [selectedDestination, setSelectedDestination] = useState('All');
  const [combinedPackages, setCombinedPackages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const scrollContainerRef = useRef(null);

  useEffect(() => {
    const fetchPackages = async () => {
      setIsLoading(true);
      const db = firebase.firestore();

      try {
        const [firebasePackages, apiPackages] = await Promise.all([
          db.collection("WildlifePackageTemplate").get().then(snapshot =>
            snapshot.docs.map(doc => ({
              id: doc.id,
              ...doc.data(),
            }))
          ),
          axios.get('https://formbackend.netlify.app/api/south-africa-cards').then(response => response.data)
        ]);

        setCombinedPackages([...firebasePackages, ...apiPackages]);
      } catch (error) {
        console.error("Error fetching packages:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchPackages();
  }, []);

 const handleDestinationClick = (destinationName) => {
    setSelectedDestination(destinationName);
  };

  const filteredPackages = selectedDestination === 'All'
    ? combinedPackages
    : combinedPackages.filter(pkg => {
        const keywords = destinationKeywords[selectedDestination] || [selectedDestination];
        return keywords.some(keyword =>
          (pkg.destinationName || pkg.name || '').toLowerCase().includes(keyword.toLowerCase())
        );
      });

  const sortedPackages = filteredPackages.sort((a, b) => a.id - b.id);

  const handleScroll = (direction) => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({
        left: direction === 'left' ? -200 : 200,
        behavior: 'smooth',
      });
    }
  };

  return (
    <div className="packages-container-search">
     <div className="scrollable-buttons-container">
        <button
          className="scroll-arrow left-arrow"
          onClick={() => handleScroll('left')}
        >
          &#8249;
        </button>
        <div className="buttons-container-search" ref={scrollContainerRef}>
          {destinations.map((destination) => (
            <button
              key={destination.name}
              className={`destination-btn-search ${selectedDestination === destination.name ? 'active' : ''}`}
              onClick={() => handleDestinationClick(destination.name)}
            >
              <div className="destination-image-search" style={{ backgroundImage: `url(${destination.image})` }}></div>
              <span>{destination.name}</span>
            </button>
          ))}
        </div>
        <button
          className="scroll-arrow right-arrow"
          onClick={() => handleScroll('right')}
        >
          &#8250;
        </button>
      </div>

      <div className="destinations-container">
        {isLoading ? (
          <div className='loading-container'>
            <p className="loading"></p>
          </div>
        ) : sortedPackages.length > 0 ? (
          <div className="best-package-list">
            {sortedPackages.map(item => (
              <div key={item.id} className="package-list-card mb-3">
                <div className="image-container">
                  <img
                    src={item.bannerImageUrl || item.image}
                    alt={item.destinationName || item.name}
                    className="list-card-img mb-3"
                  />
                  {item.isSold && (
                    <span className="sold-out-tag">Sold Out</span>
                  )}
                </div>
                <div>
                  <p className="title-list mb-3" style={{fontStyle:'italic'}}>{item.destinationHead || item.name}</p>
                  <p className="title-dates-list bold mb-3" style={{fontStyle:'italic'}}>{item.noOfDays || item.days}</p>
                  {item.corbett ? (
                    <p className='mt-3 package-template-price title-rate-list-offer'>
                      <span>
                        <b><span className="blinking-price">{item.newCost}</span></b>
                        <span className="linethrough-price"> {item.originalPrice}</span>
                        <span style={{ marginLeft: '5px' }}>PP</span>
                      </span>
                    </p>
                  ) : (
                    <p className="title-rate-list bold mb-3" style={{fontStyle:'italic'}}>{item.cost || item.price}</p>
                  )}
                  <div className="btn-container-list-card">
                    <Link
                      to={`/package/wildlife/${(item.destinationName || item.link).replace(/\s+/g, '-')}`}
                      className="blogs-link"
                    >
                      <button className="view-button-list-card" disabled={item.isSold}>View Itinerary</button>
                    </Link>
                    <Link to="/form" className="blogs-link">
                      <button className="view-button-list-card-book">Book Now</button>
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className='search-container-notfound'>
            <p className="no-results-search">No packages found for the selected destination.</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default Packages;
