import React, { useState, useEffect } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import { FiX, FiEye, FiEyeOff } from "react-icons/fi";
import { toast, Toaster } from "react-hot-toast";
import "./index.css";
import Dashboard from "../AdminDashboard";

const firebaseConfig = {
  apiKey: "AIzaSyB4BPMHKw0OXM2PhQX9NcT25lDBWpRpIPQ",
  authDomain: "phone-auth-d0a8d.firebaseapp.com",
  projectId: "phone-auth-d0a8d",
  storageBucket: "phone-auth-d0a8d.appspot.com",
  messagingSenderId: "309252587015",
  appId: "1:309252587015:web:3f9dc2505965e66269b4a2",
};


if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loggedInUser, setLoggedInUser] = useState(null);
  const [changePassword, setChangePassword] = useState(false);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");

  useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      setLoggedInUser(user ? user : null);
    });
  }, []);

  const handleLogin = async () => {
    try {
      await firebase.auth().signInWithEmailAndPassword(email, password);
      setLoggedInUser(true);
      setEmail("");
      setPassword("");
      toast.success("Logged in successfully");
    } catch (error) {
      toast.error("Please enter a valid Email or Password");
    }
  };

  const handlePasswordUpdate = async () => {
    try {
      const user = await firebase.auth().signInWithEmailAndPassword(email, oldPassword);
      await user.user.updatePassword(newPassword);
      toast.success("Password updated successfully");
      setChangePassword(false);
      setEmail("");
      setOldPassword("");
      setNewPassword("");
    } catch (error) {
      toast.error("Error updating password. Check credentials.");
    }
  };

  const handleGoback = () => {
    window.history.back();
  };

  return (
    <div className="parent-container">
      <Toaster toastOptions={{ duration: 5000 }} />
      {loggedInUser ? (
        <Dashboard />
      ) : (
        <form onSubmit={(e) => e.preventDefault()}>
          <div className="signin-container">
            <div className="element-container">
            <div
                  onClick={handleGoback}
                  style={{
                    position: "absolute",
                    top: "10px",
                    right: "10px",
                    cursor: "pointer",
                  }}
                >
                  <FiX size={30} />
                </div>
              <h3 className="login-head mb-3 text-center">{changePassword ? "Change Password" : "Login"}</h3>
              <label>Email</label>
              <input
                type="email"
                className="mb-3"
                placeholder="Enter email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              {!changePassword ? (
                <>
                  <label>Password</label>
                  <div className="password-input-container">
                    <input
                      type={showPassword ? "text" : "password"}
                      className="mb-3 password-input"
                      placeholder="Enter password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      required
                    />
                    <div className="eye-icon-container" onClick={() => setShowPassword(!showPassword)}>
                      {showPassword ? <FiEye size={20} /> : <FiEyeOff size={20} />}
                    </div>
                  </div>
                  <button onClick={handleLogin} className="btn-submit-mail">Login with Email</button>
                  <p className="change-password-text" onClick={() => setChangePassword(true)}>Change Password?</p>
                </>
              ) : (
                <>
                  <label>Old Password</label>
                  <input
                    type="password"
                    className="mb-3"
                    placeholder="Enter old password"
                    value={oldPassword}
                    onChange={(e) => setOldPassword(e.target.value)}
                    required
                  />
                  <label>New Password</label>
                  <input
                    type="password"
                    className="mb-3"
                    placeholder="Enter new password"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    required
                  />
                  <button onClick={handlePasswordUpdate} className="btn-submit-mail">Update Password</button>
                  <p className="change-password-text" onClick={() => setChangePassword(false)}>Back to Login</p>
                </>
              )}
            </div>
          </div>
        </form>
      )}
    </div>
  );
};

export default Login;